import {
  PersonalInfo,
  PersonalInfoPhotoUpdated,
  AccountActivityQueryFilters,
  AccountActivity,
} from '@/models';
import { BaseApi } from './base-api';

export class PersonalInfoApi extends BaseApi {
  async getPersonalInfo(): Promise<PersonalInfo> {
    return await this.axios
      .get<PersonalInfo>(this.config.APIConfiguration.PortalApi.GetPersonalInfo)
      .then((res) => res.data);
  }

  async updatePersonalInfo(personalInfo: PersonalInfo): Promise<PersonalInfo> {
    return await this.axios
      .put<PersonalInfo>(
        this.config.APIConfiguration.PortalApi.UpdatePersonalInfo,
        personalInfo,
      )
      .then((res) => res.data);
  }

  async updatePersonalInfoPhoto(
    imageData: Blob,
  ): Promise<PersonalInfoPhotoUpdated> {
    const formData = new FormData();
    formData.set('image', imageData);
    return await this.axios
      .post<PersonalInfoPhotoUpdated>(
        this.config.APIConfiguration.PortalApi.UpdatePersonalInfoPhoto,
        formData,
      )
      .then((data) => data.data);
  }

  async getAccountActivity(
    filters: AccountActivityQueryFilters,
  ): Promise<AccountActivity> {
    const params = Object.keys(filters).reduce((acc, key) => {
      if (!filters[key as keyof AccountActivityQueryFilters]) {
        return acc;
      }
      if (Array.isArray(filters[key as keyof AccountActivityQueryFilters])) {
        (filters[key as keyof AccountActivityQueryFilters] as string[]).forEach(
          (value) => {
            acc.append(key, value);
          },
        );
      } else {
        acc.append(
          key,
          filters[key as keyof AccountActivityQueryFilters].toString(),
        );
      }
      return acc;
    }, new URLSearchParams());

    return await this.axios
      .get<AccountActivity>(
        this.config.APIConfiguration.PortalApi.GetAccountActivity,
        { params },
      )
      .then((res) => res.data);
  }

  async getNextActivityFilters(
    filters: AccountActivityQueryFilters,
  ): Promise<AccountActivity> {
    filters.Skip += filters.Top;
    return await this.getAccountActivity(filters);
  }
}
