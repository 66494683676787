import {
  NosIdAppContextType,
  NosidBaseStateProvider,
  useBaseNosidStoreState,
} from '@nosinovacao/nosid-mfe-common';
import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import {
  AccountAssociationType,
  useAccountAssociationState,
} from './account-association.state';
import {
  ContactValidationType,
  useContactValidationState,
} from './contact-validation.state';
import { PasswordStateType, usePasswordState } from './password.state';
import { ProfilesType, useProfileState } from './profiles.state';

interface State {
  contactValidation: ContactValidationType;
  password: PasswordStateType;
  profile: ProfilesType;
  accountAssociation: AccountAssociationType;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const StateContext = createContext<State>({} as State);

export const StateProvider: FC<{
  children?: ReactNode;
  context: NosIdAppContextType;
}> = ({ children, context }) => {
  const contactValidation = useContactValidationState(
    context.sessionStorageService,
  );
  const password = usePasswordState(context.sessionStorageService);
  const profile = useProfileState(context.sessionStorageService);
  const accountAssociation = useAccountAssociationState(
    context.sessionStorageService,
  );
  const state = useMemo<State>(
    () => ({
      contactValidation,
      password,
      profile,
      accountAssociation,
    }),
    [contactValidation, password, profile, accountAssociation],
  );

  return (
    <NosidBaseStateProvider>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </NosidBaseStateProvider>
  );
};

export function useStoreState() {
  const state = useContext(StateContext);
  const baseState = useBaseNosidStoreState();
  const merged = useMemo(
    () => ({ ...state, ...baseState }),
    [state, baseState],
  );
  return merged;
}
