import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { CONTACT_VALIDATION_FLOW_STATE } from '@/constants';
import {
  AliasValidationStatusEnum,
  ContactValidationFinishAction,
  UsernameAliasToValidate,
  UsernameAliasTypeEnum,
  ValidateCodeComplete,
} from '@/models';

interface FlowState {
  finished: boolean;
  actionToExecute?: ContactValidationFinishAction;
  contactsToValidate: UsernameAliasToValidate[];
}

export interface ContactValidationType {
  contactsValidationContactsToValidate: UsernameAliasToValidate[];
  actionToExecute: ContactValidationFinishAction | undefined;
  contactsValidationMobile: UsernameAliasToValidate | undefined;
  contactsValidationMobileValidated: boolean;
  contactsValidationHasToValidateMobile: boolean;
  contactsValidationCodeInvalid: (contact: string) => boolean;
  contactsValidationEmailValidated: boolean;
  contactsValidationHasToValidateEmail: boolean;
  contactsValidationFinished: boolean;

  init: (
    contacts: UsernameAliasToValidate[],
    actionToExecute: ContactValidationFinishAction,
  ) => void;

  validateCodeComplete: (
    contact: UsernameAliasToValidate,
    codeStatus: ValidateCodeComplete,
  ) => void;

  validateCode: (contact: UsernameAliasToValidate) => void;

  resetValidatedContacts: () => void;
  sendCodeComplete: (contact: UsernameAliasToValidate) => void;
}

export function useContactValidationState(
  sessionStorageService: IStorage,
): ContactValidationType {
  const getFlowState: () => FlowState = () => {
    return (
      sessionStorageService.getObject<FlowState>(
        CONTACT_VALIDATION_FLOW_STATE,
      ) ?? {
        finished: false,
        actionToExecute: undefined,
        contactsToValidate: [],
      }
    );
  };

  const contactsValidationContactsToValidate = () =>
    getFlowState().contactsToValidate;

  const actionToExecute = () => getFlowState().actionToExecute;
  const contactsValidationMobile = () =>
    getFlowState().contactsToValidate.find(
      (contact) => contact.Type === UsernameAliasTypeEnum.Mobile,
    );

  const contactsValidationMobileValidated = () =>
    getFlowState().contactsToValidate.some(
      (contact) =>
        contact.Type === UsernameAliasTypeEnum.Mobile && contact.Code,
    );

  const contactsValidationHasToValidateMobile = () =>
    getFlowState().contactsToValidate.some(
      (contact) =>
        contact.Type === UsernameAliasTypeEnum.Mobile &&
        !contact.Code &&
        contact.Status !== AliasValidationStatusEnum.Validated,
    );

  const contactsValidationCodeInvalid = (contact: string) =>
    getFlowState().contactsToValidate.find((u) => u.Username === contact)
      ?.Status === AliasValidationStatusEnum.InvalidCode;

  const contactsValidationEmailValidated = () =>
    getFlowState().contactsToValidate.some(
      (c) => c.Type === UsernameAliasTypeEnum.Email && c.Code,
    );

  const contactsValidationHasToValidateEmail = () =>
    getFlowState().contactsToValidate.some(
      (c) => c.Type === UsernameAliasTypeEnum.Email && !c.Code,
    );

  const contactsValidationFinished = () => getFlowState().finished;

  const setStateWithSave = (
    newState: FlowState | ((curr: FlowState) => FlowState),
  ): void => {
    if (typeof newState === 'function') {
      newState = newState(getFlowState());
    }
    sessionStorageService.setObject(CONTACT_VALIDATION_FLOW_STATE, newState);
  };

  return {
    init: (
      contacts: UsernameAliasToValidate[],
      actionsToExecute: ContactValidationFinishAction,
    ) => {
      setStateWithSave((c): FlowState => {
        return {
          ...c,
          contactsToValidate: contacts,
          actionToExecute: actionsToExecute,
        };
      });
    },
    validateCodeComplete: (
      contact: UsernameAliasToValidate,
      codeStatus: ValidateCodeComplete,
    ) => {
      let isFinished = true;
      setStateWithSave((c) => {
        return {
          ...c,
          contactsToValidate: c.contactsToValidate.map(
            (curr: UsernameAliasToValidate) => {
              isFinished = isFinished && !!curr.Code;
              if (contact.Username === curr.Username) {
                return {
                  Username: contact.Username,
                  Type: contact.Type,
                  Status: codeStatus.Value
                    ? AliasValidationStatusEnum.Validated
                    : AliasValidationStatusEnum.InvalidCode,
                  Code: contact.Code,
                };
              }
              return curr;
            },
          ),
          Finished: isFinished,
        };
      });
    },
    validateCode: (contact: UsernameAliasToValidate) => {
      setStateWithSave((c) => {
        return {
          ...c,
          contactsToValidate: c.contactsToValidate.map((curr) => {
            if (contact.Username === curr.Username) {
              return {
                Username: contact.Username,
                Type: contact.Type,
                Status: AliasValidationStatusEnum.PendingCodeValidation,
                Code: contact.Code,
              };
            }

            return curr;
          }),
        };
      });
    },
    resetValidatedContacts: () => {
      setStateWithSave({
        finished: false,
        actionToExecute: undefined,
        contactsToValidate: [],
      });
    },
    sendCodeComplete: (contact: UsernameAliasToValidate) => {
      setStateWithSave((c) => {
        return {
          ...c,
          contactsToValidate: c.contactsToValidate.map((alias) => {
            if (alias.Username === contact.Username) {
              return {
                Status: AliasValidationStatusEnum.PendingCodeValidation,
                Type: contact.Type,
                Username: contact.Username,
              };
            }
            return alias;
          }),
        };
      });
    },
    get contactsValidationContactsToValidate() {
      return contactsValidationContactsToValidate();
    },
    get actionToExecute() {
      return actionToExecute();
    },
    get contactsValidationMobile() {
      return contactsValidationMobile();
    },
    get contactsValidationMobileValidated() {
      return contactsValidationMobileValidated();
    },
    get contactsValidationHasToValidateMobile() {
      return contactsValidationHasToValidateMobile();
    },
    contactsValidationCodeInvalid,
    get contactsValidationEmailValidated() {
      return contactsValidationEmailValidated();
    },
    get contactsValidationHasToValidateEmail() {
      return contactsValidationHasToValidateEmail();
    },
    get contactsValidationFinished() {
      return contactsValidationFinished();
    },
  };
}
