export enum AliasValidationStatusEnum {
  Initial = 'Initial',

  InvalidAlias = 'InvalidAlias',

  PendingSendCode = 'PendingSendCode',

  PendingCodeValidation = 'PendingCodeValidation',

  InvalidCode = 'InvalidCode',

  Validated = 'Validated',
}
