import { ExternalLoginProviders } from '@nosinovacao/nosid-mfe-common';
import { ExternalProvider } from './external-provider';

type AppleLoginClient = AppleSignInAPI.AppleID;
type W = Window &
  typeof globalThis & {
    AppleID?: AppleLoginClient;
  };

export class AppleExternalProvider extends ExternalProvider<AppleSignInAPI.AuthI> {
  async initProvider(): Promise<AppleSignInAPI.AuthI | null> {
    return await new Promise((resolve) => {
      //	Async load apple sdk script
      const node = document.createElement('script');
      node.src = this.config.ExternalLoginProviders.Apple.API;
      node.async = true;

      // 	Retrieve the singleton for the FB library on load script
      node.onload = () => {
        (window as W).AppleID?.auth.init({
          clientId: this.config.ExternalLoginProviders.Apple.ClientId,
          usePopup: true,
          redirectURI: window.location.origin,
        });
        resolve((window as W).AppleID?.auth);
      };

      node.onerror = () => {
        resolve(null);
      };

      //	Inject script into head element
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  async login(): Promise<boolean> {
    return await this.oAuthClient.then(async (client) => {
      return (
        (await client
          ?.signIn()
          .then(async (response: AppleSignInAPI.SignInResponseI) => {
            return await this.addSocialNetwork(
              ExternalLoginProviders.Apple,
              response.authorization.id_token,
            );
          })
          .catch(() => {
            throw new Error();
          })) ?? false
      );
    });
  }

  async logout(): Promise<void> {
    await this.finishLogout(ExternalLoginProviders.Apple);
  }
}
