import { AppInfoWithPrivacyFlags, PrivacyFlagsUpdate } from '@/models';
import { BaseApi } from './base-api';

export class PrivacyPoliciesApi extends BaseApi {
  async getPrivacyPolicies(): Promise<AppInfoWithPrivacyFlags[]> {
    return await this.axios
      .get<AppInfoWithPrivacyFlags[]>(
        this.config.APIConfiguration.PortalApi.GetPrivacyPolicies,
      )
      .then((res) => res.data);
  }

  async updatePrivacyPolicies(
    privacyFlagsUpdate: PrivacyFlagsUpdate,
  ): Promise<void> {
    await this.axios.put<PrivacyFlagsUpdate>(
      this.config.APIConfiguration.PortalApi.UpdatePrivacyPolicies,
      privacyFlagsUpdate,
    );
  }
}
