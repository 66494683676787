import { lazy } from 'react';

const LandingPage = lazy(
  async () => await import('@/pages/AccountAssociation/LandingPage'),
);
const HolderInput = lazy(
  async () => await import('@/pages/AccountAssociation/HolderInput'),
);

const ContactSelection = lazy(
  async () => await import('@/pages/AccountAssociation/ContactSelection'),
);

const SendSms = lazy(
  async () => await import('@/pages/AccountAssociation/SendSms'),
);

const CodeInput = lazy(
  async () => await import('@/pages/AccountAssociation/CodeInput'),
);

const Resume = lazy(
  async () => await import('@/pages/AccountAssociation/Resume'),
);

export default [
  {
    path: 'account-association/landing-page',
    element: <LandingPage />,
  },
  {
    path: 'account-association/holder-input/:type',
    element: <HolderInput />,
  },
  {
    path: 'account-association/contact-selection',
    element: <ContactSelection />,
  },
  {
    path: 'account-association/send-sms',
    element: <SendSms />,
  },
  {
    path: 'account-association/code-input',
    element: <CodeInput />,
  },
  {
    path: 'account-association/resume',
    element: <Resume />,
  },
];
