import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { PROFILE_FLOW_STATE } from '@/constants';
import { Profile } from '@/models';

export interface ProfilesType {
  getProfile: () => Profile | null;
  setProfile: (p: Profile) => Profile;
  resetProfile: () => void;
}

export function useProfileState(sessionStorageService: IStorage): ProfilesType {
  return {
    getProfile: () =>
      sessionStorageService.getObject<Profile>(PROFILE_FLOW_STATE),
    setProfile: (p: Profile) => {
      sessionStorageService.setObject(PROFILE_FLOW_STATE, p);
      return p;
    },
    resetProfile: () => {
      sessionStorageService.remove(PROFILE_FLOW_STATE);
    },
  };
}
