import { Avatar, Profile } from '@/models';
import { BaseApi } from './base-api';

export class ProfilesApi extends BaseApi {
  async getProfiles(serviceAccountId: string): Promise<Profile[]> {
    const url = `${this.config.APIConfiguration.PortalApi.GetProfiles}/${serviceAccountId}`;
    return await this.axios.get<Profile[]>(url).then((res) => res.data);
  }

  async addProfile(profile: Profile): Promise<Profile> {
    return await this.axios
      .post(this.config.APIConfiguration.PortalApi.AddProfile, profile)
      .then((res) => res.data);
  }

  async updateProfile(profile: Profile): Promise<Profile> {
    return await this.axios.put(
      this.config.APIConfiguration.PortalApi.UpdateProfile,
      profile,
    );
  }

  async deleteProfile(
    serviceAccountId: string,
    profileId: string,
  ): Promise<boolean> {
    const url = `${this.config.APIConfiguration.PortalApi.DeleteProfile}/${serviceAccountId}/${profileId}`;
    return await this.axios.delete(url).then(() => true);
  }

  async getAvatars(avatarType: string): Promise<Avatar[]> {
    return await this.axios
      .get<Avatar[]>(this.config.APIConfiguration.PortalApi.GetAvatars, {
        params: {
          type: avatarType,
        },
      })
      .then((res) => res.data);
  }
}
