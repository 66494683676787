import { lazy } from 'react';

const LandingPage = lazy(
  async () => await import('@/pages/ProfileSelection/LandingPage'),
);
const NewProfile = lazy(
  async () => await import('@/pages/ProfileSelection/NewProfile'),
);
const Avatar = lazy(
  async () => await import('@/pages/ProfileSelection/Avatar'),
);

export default [
  {
    path: 'profile-selection',
    element: <LandingPage />,
  },
  {
    path: 'profile-selection/new',
    element: <NewProfile />,
  },
  {
    path: 'profile-selection/new/avatars',
    element: <Avatar />,
  },
];
