import { lazy } from 'react';

const Info = lazy(async () => await import('@/pages/Account/Info'));
const Services = lazy(async () => await import('@/pages/Account/Services'));
const Pin = lazy(async () => await import('@/pages/Account/Pin'));
const UserDeleted = lazy(
  async () => await import('@/pages/Account/UserDeleted'),
);

export default [
  {
    path: 'account',
    element: <Info />,
  },
  {
    path: 'account/services',
    element: <Services />,
  },
  {
    path: 'account/services/:id',
    element: <Pin />,
  },
  {
    path: 'account/userDeleted',
    element: <UserDeleted />,
  },
];
