import { Application, User, SecondaryUser } from '@/models';
import { BaseApi } from './base-api';

export class UsersApi extends BaseApi {
  async getUsers(): Promise<User[]> {
    return await this.axios
      .get<User[]>(this.config.APIConfiguration.PortalApi.GetUsers)
      .then((res) => res.data)
      .catch((e) => []);
  }

  async createSecondaryUser(user: SecondaryUser): Promise<User> {
    const body = {
      DisplayName: user.Name,
      Email: user.Email,
    };
    return await this.axios
      .post(this.config.APIConfiguration.PortalApi.CreateUser, body)
      .then((res) => res.data);
  }

  async deleteSecondaryUser(id: string): Promise<void> {
    await this.axios.delete(
      this.config.APIConfiguration.PortalApi.DeleteSecondaryUser.replace(
        ':userId',
        id,
      ),
    );
  }

  async deleteUser(): Promise<void> {
    await this.axios.delete(
      `${this.config.APIConfiguration.PortalApi.DeleteUser}`,
    );
  }

  async deleteSecondaryAssociation(): Promise<any> {
    //	AS A SECONDARY USER "I WANT TO BREAK FREE" :{ FROM MY ACTUAL PRIMARY USER YEEEEE OOOOOOHHHHH
    return await this.axios.delete(
      this.config.APIConfiguration.PortalApi.DeleteSecondaryUser.replace(
        ':userId',
        '',
      ),
    );
  }

  async getSecondaryUserApps(id: string): Promise<Application[]> {
    return await this.axios
      .get<Application[]>(
        `${this.config.APIConfiguration.PortalApi.GetSecondaryUserApplications}/${id}`,
      )
      .then((res) => res.data);
  }

  async updateAppPermission(
    userId: string,
    appId: string,
    value: boolean,
  ): Promise<void> {
    const url = `${this.config.APIConfiguration.PortalApi.UpdateSecondaryUserApplicationPermission.replace(
      ':app-id',
      appId,
    ).replace(':user-id', userId)}`;
    await this.axios.post(
      url,
      {},
      {
        params: {
          isEnabled: String(value),
        },
      },
    );
  }
}
