import { lazy } from 'react';

const LandingPage = lazy(async () => await import('@/pages/Tv/LandingPage'));
const NewProfile = lazy(async () => await import('@/pages/Tv/NewProfile'));
const EditProfile = lazy(async () => await import('@/pages/Tv/EditProfile'));
const Avatar = lazy(async () => await import('@/pages/Tv/Avatar'));

export default [
  {
    path: 'tv',
    element: <LandingPage />,
  },
  {
    path: 'tv/:sa/profile/new',
    element: <NewProfile />,
  },
  {
    path: 'tv/:sa/profile/:id',
    element: <EditProfile />,
  },
  {
    path: 'tv/profile/:id/edit',
    element: <EditProfile redirectOnSuccess noBack defaultSa />,
  },
  {
    path: 'tv/select-avatar/:type',
    element: <Avatar />,
  },
];
