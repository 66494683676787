import {
  ClientTemplate,
  IStorage,
  NosIdAppContextType,
  WidgetConfiguration,
  useNosIdAppContext,
} from '@nosinovacao/nosid-mfe-common';
import React, { useMemo } from 'react';
import {
  AccountApi,
  AppleExternalProvider,
  ApplicationsApi,
  FacebookExternalProvider,
  GoogleExternalProvider,
  LoginOptionsApi,
  PersonalInfoApi,
  PrivacyPoliciesApi,
  SecurityApi,
  UsersApi,
} from '@/api';
import { AccountAssociationApi } from '@/api/account-association.api';
import { DeviceApi } from '@/api/device.api';
import { ExternalProvider } from '@/api/external-providers/external-provider';
import { ProfilesApi } from '@/api/profiles.api';
import { PortalConfiguration } from '@/models';
import { Auth, UtilsService } from '@/services';

export interface AppContextType {
  utilsService: UtilsService;
  auth: Auth;
  personalInfoApi: PersonalInfoApi;
  applicationsApi: ApplicationsApi;
  privacyPoliciesApi: PrivacyPoliciesApi;
  securityApi: SecurityApi;
  usersApi: UsersApi;
  loginOptionsApi: LoginOptionsApi;
  googleProvider: ExternalProvider;
  facebookProvider: ExternalProvider;
  appleProvider: ExternalProvider;
  accountApi: AccountApi;
  profilesApi: ProfilesApi;
  deviceApi: DeviceApi;
  accountAssociationApi: AccountAssociationApi;
}

export const AppContext = React.createContext<AppContextType>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AppContextType,
);

type PortalContext = NosIdAppContextType &
  AppContextType & { config: PortalConfiguration };

export function useAppContext(): PortalContext {
  const nosidContext = useNosIdAppContext();
  const portalContext = React.useContext(AppContext);

  return useMemo(() => {
    return {
      ...nosidContext,
      ...portalContext,
      config: nosidContext.config as PortalConfiguration,
    };
  }, [nosidContext, portalContext]);
}

export function createApplicationContext(
  env: PortalConfiguration,
  templates: ClientTemplate[],
  widgets: WidgetConfiguration,
  localStorageService: IStorage,
  sessionStorageService: IStorage,
  nosidContext: NosIdAppContextType,
): AppContextType {
  const auth = new Auth(
    env,
    localStorageService,
    sessionStorageService,
    nosidContext,
  );
  return {
    auth,
    utilsService: new UtilsService(),
    personalInfoApi: new PersonalInfoApi(auth, env),
    applicationsApi: new ApplicationsApi(auth, env),
    securityApi: new SecurityApi(auth, env),
    privacyPoliciesApi: new PrivacyPoliciesApi(auth, env),
    usersApi: new UsersApi(auth, env),
    loginOptionsApi: new LoginOptionsApi(auth, env),
    googleProvider: new GoogleExternalProvider(auth, env),
    facebookProvider: new FacebookExternalProvider(auth, env),
    appleProvider: new AppleExternalProvider(auth, env),
    accountApi: new AccountApi(auth, env),
    profilesApi: new ProfilesApi(auth, env),
    deviceApi: new DeviceApi(auth, env),
    accountAssociationApi: new AccountAssociationApi(auth, env),
  };
}
