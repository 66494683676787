import { PasswordRequirements } from '@nosinovacao/nosid-mfe-common';
import { AxiosResponse } from 'axios';
import {
  CodeTypeEnum,
  PasswordRequirementsResponse,
  PasswordValidation,
  SecurityCode,
  SendSecurityCode,
  SendSmsCompleted,
  UpdatePassword,
  UsernameAliasTypeEnum,
  ValidateCodeComplete,
} from '@/models';
import { BaseApi } from './base-api';

export class SecurityApi extends BaseApi {
  async sendCode(
    username: string,
    type: UsernameAliasTypeEnum,
    captchaToken: string | null | undefined,
    challenge: boolean,
  ): Promise<SendSmsCompleted> {
    return await this.axios
      .post<SendSecurityCode, AxiosResponse<SendSmsCompleted>>(
        this.config.APIConfiguration.PortalApi.SendCode,
        this.buildSendCode(username, type),
        {
          headers: {
            [this.CAPTCHA_HTTP_HEADER]: captchaToken,
            [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: challenge,
          },
        },
      )
      .then((res) => res.data);
  }

  async validateCode(
    username: string,
    type: UsernameAliasTypeEnum,
    code: string,
  ): Promise<ValidateCodeComplete> {
    return await this.axios
      .post<SecurityCode, AxiosResponse<ValidateCodeComplete>>(
        this.config.APIConfiguration.PortalApi.ValidateCode,
        this.buildValidateCode(username, type, code),
      )
      .then((res) => res.data);
  }

  async activateTwoFactorAuth(
    username: string,
    type: UsernameAliasTypeEnum,
    code: string,
  ): Promise<boolean> {
    return await this.axios
      .post(
        this.config.APIConfiguration.PortalApi.ActivateTwoFactorAuth,
        this.buildValidateCode(username, type, code),
      )
      .then(() => true);
  }

  async disableTwoFactorAuth(): Promise<boolean> {
    return await this.axios
      .post(this.config.APIConfiguration.PortalApi.DisableTwoFactorAuth, null)
      .then(() => true);
  }

  async validateCurrentPassword(password: PasswordValidation): Promise<any> {
    return await this.axios.post(
      this.config.APIConfiguration.PortalApi.ValidateCurrentPassword,
      password,
    );
  }

  async getPasswordRequirements(): Promise<PasswordRequirements> {
    return await this.axios
      .get<PasswordRequirementsResponse>(
        this.config.APIConfiguration.PortalApi.GetPasswordRequirements,
      )
      .then((res) => res.data.RequirementsAndRegex);
  }

  async updatePassword(newPasswordToUpdate: UpdatePassword): Promise<any> {
    return await this.axios.post(
      this.config.APIConfiguration.PortalApi.UpdatePassword,
      newPasswordToUpdate,
    );
  }

  async invalidateSessions(): Promise<any> {
    return await this.axios.put(
      this.config.APIConfiguration.PortalApi.InvalidateSessions,
      {},
    );
  }

  //    AUXILIARY METHODS
  private buildValidateCode(
    username: string,
    type: UsernameAliasTypeEnum,
    code: string,
  ): SecurityCode {
    return {
      ...this.buildSendCode(username, type),
      Code: code,
    };
  }

  private buildSendCode(
    username: string,
    type: UsernameAliasTypeEnum,
  ): SendSecurityCode {
    return {
      TargetId: username,
      Type:
        type === UsernameAliasTypeEnum.Mobile
          ? CodeTypeEnum.SMS
          : CodeTypeEnum.Email,
    };
  }
}
