import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { PASSWORD_RECOVERY_STATE } from '@/constants';

export interface PasswordState {
  InRecoveryMode: boolean;
  CurrentPassword: string;
  CurrentPasswordInvalid: boolean;
}

export interface PasswordStateType {
  getState: () => PasswordState;
  setState: (state: PasswordState) => void;
  setInitialState: () => void;
}

export function usePasswordState(
  sessionStorageService: IStorage,
): PasswordStateType {
  return {
    getState: () => {
      return (
        sessionStorageService.getObject<PasswordState>(
          PASSWORD_RECOVERY_STATE,
        ) ?? {
          InRecoveryMode: false,
          CurrentPassword: '',
          CurrentPasswordInvalid: false,
        }
      );
    },
    setState: (setState) => {
      sessionStorageService.setObject(PASSWORD_RECOVERY_STATE, setState);
    },
    setInitialState: () => {
      sessionStorageService.setObject(PASSWORD_RECOVERY_STATE, {
        InRecoveryMode: false,
        CurrentPassword: '',
        CurrentPasswordInvalid: false,
      });
    },
  };
}
