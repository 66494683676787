import { Device } from '@/models';
import { BaseApi } from './base-api';

export class DeviceApi extends BaseApi {
  async getDevices(accountId: string): Promise<Device[]> {
    return await this.axios
      .get<Device[]>(
        this.config.APIConfiguration.PortalApi.GetDevices.replace(
          ':accountId',
          accountId,
        ),
      )
      .then((res) => res.data);
  }

  async deleteDevice(accountId: string, deviceId: string): Promise<any> {
    await this.axios.delete(
      this.config.APIConfiguration.PortalApi.DeleteDevice.replace(
        ':accountId',
        accountId,
      ).replace(':deviceId', deviceId),
    );
  }
}
