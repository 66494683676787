import {
  bootstrapApp,
  createNosIdApplicationContext,
  setUpI18n,
} from '@nosinovacao/nosid-mfe-common';
import moment from 'moment';
import ReactDOM from 'react-dom/client';
import { PortalConfiguration } from '@/models';
import { App } from '@/App';
import './index.scss';

import {
  CLIENT_ID_STORAGE_KEY,
  LOGIN_HINT_STORAGE_KEY,
  REDIRECT_URI_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
} from '@/constants';
import { createApplicationContext } from '@/context';

moment.locale('pt', {
  months:
    'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
      '_',
    ),
});

void bootstrapApp<PortalConfiguration>(async ({ env, templates, widgets }) => {
  const nosidContext = createNosIdApplicationContext(
    env,
    templates,
    widgets,
    CLIENT_ID_STORAGE_KEY,
    'Portal',
    env.APIConfiguration.Mage,
  );
  const context = createApplicationContext(
    env,
    templates,
    widgets,
    nosidContext.localStorageService,
    nosidContext.sessionStorageService,
    nosidContext,
  );

  const params = new URLSearchParams(window.location.search);
  const loginHint = context.utilsService.sanitizeUrl(
    params.get('login_hint')?.replace(/ /g, '+') ?? '',
    '',
  );
  const redirectUri = context.utilsService.sanitizeUrl(
    params.get('redirect_uri') ?? '',
    '',
  );

  const loginHintStorage = nosidContext.sessionStorageService.getString(
    LOGIN_HINT_STORAGE_KEY,
  );

  if (loginHint) {
    if (nosidContext.templates.isWooTemplate) {
      if (loginHintStorage && loginHintStorage !== loginHint) {
        await context.auth.logout();
      }
    }
    nosidContext.sessionStorageService.setString(
      LOGIN_HINT_STORAGE_KEY,
      loginHint,
    );
  }
  if (redirectUri) {
    nosidContext.sessionStorageService.setString(
      REDIRECT_URI_STORAGE_KEY,
      redirectUri,
    );
  }
  const token = params.get('token');
  if (token) {
    nosidContext.sessionStorageService.setString(TOKEN_STORAGE_KEY, token);
  }

  if (!nosidContext.templates.clientTemplate.hideCookiebot) {
    nosidContext.cookieBotService.addCookiebot();
  }

  await setUpI18n();

  if (nosidContext.templates.isWooTemplate) {
    const currentContext = localStorage.getItem('nosid-context');
    if (!currentContext || currentContext !== 'woo') {
      localStorage.setItem('nosid-context', 'woo');
    }
  } else {
    localStorage.removeItem('nosid-context');
  }

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App context={context} nosidContext={nosidContext} />,
  );
});
