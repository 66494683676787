import Axios, { AxiosInstance } from 'axios';
import { Auth } from '@/services';
import { PortalConfiguration } from '@/models';

export class BaseApi {
  axios: AxiosInstance;
  protected readonly CAPTCHA_HTTP_HEADER = 'X-Core-Captcha';
  protected readonly CAPTCHA_CHALLENGE_HTTP_HEADER = 'X-Core-Captcha-Challenge';

  constructor(auth: Auth, protected readonly config: PortalConfiguration) {
    // create axios instance
    this.axios = Axios.create({
      baseURL: config.APIConfiguration.PortalApi.BaseUrl,
    });

    this.axios.interceptors.request.use(async (c) => {
      const token = await auth.getToken();
      c.headers.Authorization = `Bearer ${token ?? ''}`;
      c.headers['x-enterprise-name'] = localStorage.getItem('nosid-context');
      return c;
    });
  }
}
