import { lazy } from 'react';

const Home = lazy(async () => await import('@/pages/Home/LandingPage'));

export default [
  {
    index: true,
    element: <Home />,
  },
];
