import { NOSIDError } from '@nosinovacao/nosid-mfe-common';
import {
  AccountAssociationContact,
  AccountAssociationTypeEnum,
  AccountAssociationValidateContact,
  AccountAssociationValidateContactComplete,
  AccountServices,
  SendSmsCompleted,
} from '@/models';
import { BaseApi } from './base-api';

export class AccountAssociationApi extends BaseApi {
  async getAccountContacts(
    value: string,
    type: AccountAssociationTypeEnum,
  ): Promise<AccountAssociationContact[]> {
    return await this.axios
      .get<AccountAssociationContact[]>(
        this.config.APIConfiguration.PortalApi.GetAccountAssociationContacts.replace(
          ':value',
          value,
        ).replace(':type', type),
      )
      .then((res) => res.data);
  }

  async validateContact(
    value: string,
    type: AccountAssociationTypeEnum,
  ): Promise<NOSIDError | null> {
    return await this.axios
      .get<NOSIDError>(
        this.config.APIConfiguration.PortalApi.EnsureAccountAssociationContactIsValid.replace(
          ':contact',
          value,
        ).replace(':type', type),
      )
      .then(() => null)
      .catch((error) => error.response?.data as NOSIDError);
  }

  async sendCode(
    contact: AccountAssociationValidateContact,
    captchaToken: string | null,
    challenge: boolean,
  ): Promise<SendSmsCompleted> {
    return await this.axios
      .post<SendSmsCompleted>(
        this.config.APIConfiguration.PortalApi.SendAccountAssociationCode,
        {
          TargetId: contact.TargetId,
          IdentificationType: contact.IdentificationType,
          ContactId: contact.ContactId ? contact.ContactId : undefined,
        },
        {
          headers: {
            [this.CAPTCHA_HTTP_HEADER]: captchaToken,
            [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: challenge,
          },
        },
      )
      .then((res) => res.data);
  }

  async validateCode(
    contact: AccountAssociationValidateContact,
  ): Promise<AccountAssociationValidateContactComplete> {
    return await this.axios
      .post<AccountAssociationValidateContactComplete>(
        this.config.APIConfiguration.PortalApi
          .ValidateAccountAssociationContact,
        {
          TargetId: contact.TargetId,
          IdentificationType: contact.IdentificationType,
          ContactId: contact.ContactId ? contact.ContactId : undefined,
          Code: contact.Code,
        },
      )
      .then((res) => res.data);
  }

  async commitAssociationResume(
    contact: AccountAssociationValidateContact,
    confirm = false,
  ): Promise<AccountServices> {
    return await this.axios
      .post<AccountServices>(
        this.config.APIConfiguration.PortalApi.AccountAssociationResume.replace(
          ':confirm',
          confirm.toString(),
        ),
        {
          TargetId: contact.TargetId,
          IdentificationType: contact.IdentificationType,
          ContactId: contact.ContactId ? contact.ContactId : undefined,
          Code: contact.Code,
        },
      )
      .then((res) => res.data);
  }
}
