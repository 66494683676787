import { Application } from '@/models';
import { BaseApi } from './base-api';

export class ApplicationsApi extends BaseApi {
  async getApplications(): Promise<Application[]> {
    return await this.axios
      .get<Application[]>(
        this.config.APIConfiguration.PortalApi.GetApplications,
      )
      .then((res) => res.data);
  }

  async getApplicationsNames(): Promise<Record<string, string>> {
    return await this.axios
      .get(this.config.APIConfiguration.PortalApi.GetApplicationsNames)
      .then((res) => res.data);
  }

  async requestAccess(appId: string): Promise<boolean> {
    return await this.axios
      .post(
        `${this.config.APIConfiguration.PortalApi.ApplicationAccessRequest}/${appId}`,
      )
      .then((res) => true);
  }
}
