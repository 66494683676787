import { lazy } from 'react';

const CodeInput = lazy(
  async () => await import('@/pages/ContactsValidation/CodeInput'),
);

const SendSms = lazy(
  async () => await import('@/pages/ContactsValidation/sendSms'),
);

export default [
  {
    path: 'contacts-validation/send-sms',
    element: <SendSms />,
  },
  {
    path: 'contacts-validation/code-input/:type',
    element: <CodeInput />,
  },
];
