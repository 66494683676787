import {
  AliasValidationComplete,
  CodeTypeEnum,
  LoginOptions,
  UsernameAlias,
  UsernameAliasToValidate,
  UsernameAliasTypeEnum,
} from '@/models';
import { BaseApi } from './base-api';

export class LoginOptionsApi extends BaseApi {
  async getLoginOptions(): Promise<LoginOptions> {
    return await this.axios
      .get<LoginOptions>(this.config.APIConfiguration.PortalApi.GetLoginOptions)
      .then((res) => res.data);
  }

  async updateLoginOptions(
    aliases: UsernameAliasToValidate[],
  ): Promise<UsernameAlias[]> {
    return await this.axios
      .put<UsernameAlias[]>(
        this.config.APIConfiguration.PortalApi.UpdateLoginOptions,
        {
          UsernameAliases: aliases.map((a) => {
            return {
              Username: a.Username,
              Type: a.Type,
            };
          }),
          SecurityCodes: aliases
            .filter((a) => a.Type !== UsernameAliasTypeEnum.Other)
            .map((a) => {
              return {
                TargetId: a.Username,
                Type:
                  a.Type === UsernameAliasTypeEnum.Email
                    ? CodeTypeEnum.Email
                    : CodeTypeEnum.SMS,
                Code: a.Code,
              };
            }),
        },
      )
      .then((res) => res.data);
  }

  async validateAlias(
    aliases: UsernameAliasToValidate[],
  ): Promise<AliasValidationComplete> {
    return await this.axios
      .post<AliasValidationComplete>(
        this.config.APIConfiguration.PortalApi.ValidateAliases,
        aliases,
      )
      .then((res) => res.data);
  }
}
