import { AccountServices, ServiceAccountPin } from '@/models';
import { BaseApi } from './base-api';

export class AccountApi extends BaseApi {
  async getAccountServices(): Promise<AccountServices> {
    return await this.axios
      .get<AccountServices>(this.config.APIConfiguration.PortalApi.GetServices)
      .then((response) => response.data);
  }

  async setDefaultAccount(id: string): Promise<any> {
    const body = {
      ServiceAccount: id,
    };

    return await this.axios
      .post(
        this.config.APIConfiguration.PortalApi.SetDefaultServiceAccount,
        body,
      )
      .then((res) => res.data);
  }

  async getPins(id: string): Promise<ServiceAccountPin[]> {
    return await this.axios
      .get<ServiceAccountPin[]>(
        this.config.APIConfiguration.PortalApi.GetServicePins.replace(
          ':service-id',
          id,
        ),
      )
      .then((res) => res.data);
  }

  async setPins(id: string, pins: ServiceAccountPin[]): Promise<any> {
    return await this.axios
      .post<ServiceAccountPin[]>(
        this.config.APIConfiguration.PortalApi.SetServicePins.replace(
          ':service-id',
          id,
        ),
        pins,
      )
      .then((res) => res.data);
  }
}
