import { lazy } from 'react';

const DeviceManagement = lazy(
  async () => await import('@/pages/DeviceManagement/LandingPage'),
);

export default [
  {
    path: 'devices/:sa',
    element: <DeviceManagement />,
  },
  {
    path: 'devices',
    element: <DeviceManagement noBack deleteDeviceAndRedirect resolveSa />,
  },
];
