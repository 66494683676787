import { IStorage } from '@nosinovacao/nosid-mfe-common';
import { ACCOUNT_ASSOCIATION_FLOW_STATE } from '@/constants';
import {
  AccountAssociationContact,
  AccountAssociationTypeEnum,
  AccountAssociationValidateContact,
  AccountServices,
} from '@/models';

interface AccountAssociationFlowModel {
  hasError: boolean;

  PrimarySelection: {
    Type: AccountAssociationTypeEnum;
    Value: string;
  } | null;

  SecondaryContacts: AccountAssociationContact[];
  SecondaryContactSelection: AccountAssociationContact | null;

  ComunicationAuthorized: boolean;

  ContactValidationData: AccountAssociationValidateContact | null;

  AccountServices: AccountServices | null;
}

export interface AccountAssociationType {
  getState: () => AccountAssociationFlowModel;
  setState: (val: AccountAssociationFlowModel | null | undefined) => void;
  initialState: AccountAssociationFlowModel;
}

const initialState: AccountAssociationFlowModel = {
  hasError: false,
  PrimarySelection: null,
  SecondaryContacts: [],
  SecondaryContactSelection: null,
  ComunicationAuthorized: false,
  ContactValidationData: null,
  AccountServices: null,
};

export function useAccountAssociationState(
  sessionStorage: IStorage,
): AccountAssociationType {
  return {
    getState() {
      return (
        sessionStorage.getObject<AccountAssociationFlowModel>(
          ACCOUNT_ASSOCIATION_FLOW_STATE,
        ) ?? initialState
      );
    },
    initialState,
    setState: (value) => {
      if (!value) {
        sessionStorage.remove(ACCOUNT_ASSOCIATION_FLOW_STATE);
        return;
      }
      sessionStorage.setObject(ACCOUNT_ASSOCIATION_FLOW_STATE, value);
    },
  };
}
