import { ExternalLoginProviders } from '@nosinovacao/nosid-mfe-common';
import { ExternalProvider } from './external-provider';

export class GoogleExternalProvider extends ExternalProvider {
  private client!: google.accounts.oauth2.TokenClient;

  async initProvider(): Promise<null> {
    throw new Error();
  }

  async login(): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      const node = document.createElement('script');
      node.src = this.config.ExternalLoginProviders.Google.API;
      node.defer = true;
      node.onload = async () => {
        this.client = google.accounts.oauth2.initTokenClient({
          client_id: this.config.ExternalLoginProviders.Google.ClientId,
          scope: this.config.ExternalLoginProviders.Google.Scope,
          callback: async (response) => {
            await this.addSocialNetwork(
              ExternalLoginProviders.Google,
              response.access_token ?? '',
            )
              .then((resp) => {
                resolve(resp);
              })
              .catch((error) => {
                reject(error);
              });
          },
          error_callback: (error) => {
            reject(error);
          },
        });

        this.client.requestAccessToken();
      };

      node.onerror = () => {
        resolve(false);
      };

      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  async logout(): Promise<void> {
    await this.finishLogout(ExternalLoginProviders.Google);
  }
}
