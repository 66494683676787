import { lazy } from 'react';

const AppsList = lazy(async () => await import('@/pages/Apps/AppsList'));
const Consents = lazy(async () => await import('@/pages/Apps/Consents'));

export default [
  {
    path: 'apps',
    element: <AppsList />,
  },
  {
    path: 'apps/consents',
    element: <Consents />,
  },
];
