import { lazy } from 'react';

// users Module
const UsersLandingPage = lazy(
  async () => await import('@/pages/Users/LandingPage'),
);
const NewUser = lazy(async () => await import('@/pages/Users/New'));
const UserInfo = lazy(async () => await import('@/pages/Users/Info'));

export default [
  {
    path: 'users',
    element: <UsersLandingPage />,
  },
  {
    path: 'users/new',
    element: <NewUser />,
  },
  {
    path: 'users/:id',
    element: <UserInfo />,
  },
];
