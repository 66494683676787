import { FC, lazy, Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from 'react-router-dom';
import { LOGIN_REDIRECT_URI, SILENT_REDIRECT_URI } from '@/constants';
import AccountRoutes from '@/pages/Account';
import AccountAssociationRoutes from '@/pages/AccountAssociation';
import AppsRoutes from '@/pages/Apps';
import ContactsValidationRoutes from '@/pages/ContactsValidation';
import DeviceManagementRoutes from '@/pages/DeviceManagement';
import HomeRoutes from '@/pages/Home';
import ProfileSelectionRoutes from '@/pages/ProfileSelection';
import SecurityRoutes from '@/pages/Security';
import TvRoutes from '@/pages/Tv';
import UsersRoutes from '../pages/Users';
// Auth Routes
const LoginRedirect = lazy(
  async () => await import('@/components/auth/LoginRedirect'),
);
const SilentRedirect = lazy(
  async () => await import('@/components/auth/SilentRedirect'),
);
const MainLayout = lazy(async () => await import('@/components/layout'));

export const Router: FC = () => {
  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: LOGIN_REDIRECT_URI,
          element: (
            <Suspense>
              <LoginRedirect />
            </Suspense>
          ),
        },
        {
          path: SILENT_REDIRECT_URI,
          element: (
            <Suspense>
              <SilentRedirect />
            </Suspense>
          ),
        },
        {
          path: '/',
          element: (
            <Suspense>
              <MainLayout />
            </Suspense>
          ),
          children: [
            ...HomeRoutes,
            ...UsersRoutes,
            ...ContactsValidationRoutes,
            ...AppsRoutes,
            ...SecurityRoutes,
            ...AccountRoutes,
            ...TvRoutes,
            ...ProfileSelectionRoutes,
            ...DeviceManagementRoutes,
            ...AccountAssociationRoutes,
            {
              path: '*',
              element: <Redirect to="/" />,
            },
          ],
        },
      ])}
    />
  );
};

export function Redirect({ to: url }: { to: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (url.startsWith('http')) {
      window.location.href = url;
    } else {
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
