import {
  PhoneService,
  AnalyticsOption,
  ActionType,
  EventParameters,
  ModuleName,
} from '@nosinovacao/nosid-mfe-common';
import { UsernameAliasToValidate } from '@/models';

export class UtilsService {
  reloadApp(): void {
    window.location.href = window.location.origin;
  }

  refresh(): void {
    window.location.reload();
  }

  redirect(url: string): void {
    window.location.href = this.sanitizeUrl(url, window.location.origin);
  }

  openInNewWindow(link: string): void {
    window.open(link);
  }

  get pathname(): string {
    return window.location.pathname;
  }

  isNumeric(val: string): boolean {
    return val.trim().length > 0 && !Number.isNaN(+val);
  }

  getLogOption(
    phoneService: PhoneService,
    alias: string,
    aliasType: string,
  ): string {
    if (aliasType === 'Mobile') {
      const { Code } =
        phoneService.parseCountryCode(alias) ||
        phoneService.getDefaultCountry();
      return `${AnalyticsOption.Phone}@${Code}`;
    }

    return aliasType;
  }

  processEventParameters(
    actionType: ActionType,
    usernameAlias: UsernameAliasToValidate,
    phoneService: PhoneService,
  ): EventParameters {
    return {
      ActionType: actionType,
      Option: this.getLogOption(
        phoneService,
        usernameAlias.Username,
        usernameAlias.Type,
      ),
    };
  }

  sanitizeUrl(url: string, def: string): string {
    if (!url?.trim?.()) {
      return def;
    }
    if (this.isBase64(url)) {
      url = atob(url);
    }

    // eslint-disable-next-line no-script-url
    if (url.includes('javascript:') || url.includes('data:text/html')) {
      return def;
    }

    return url;
  }

  private isBase64(str: string): boolean {
    if (!str?.trim?.()) {
      return false;
    }
    try {
      const reg =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (reg.test(str)) {
        return true;
      }
      const reEncodedValue = btoa(atob(str));
      if (
        reEncodedValue
          .replace(str, '')
          .split('')
          .every((char) => char === '=')
      ) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }

  private static readonly routes: Map<string, string> = new Map<
    ModuleName,
    string
  >([
    [ModuleName.Home, '/'],
    [ModuleName.Tv, '/tv'],
    [ModuleName.Applications, '/apps'],
    [ModuleName.Account, '/account'],
    [ModuleName.Users, '/users'],
    [ModuleName.Security, '/security'],
  ]);

  static getRoutePath(module: ModuleName): string | undefined {
    return UtilsService.routes.get(module);
  }
}
