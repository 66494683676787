import { lazy } from 'react';

const LandingPage = lazy(
  async () => await import('@/pages/Security/LandingPage'),
);

const ChangePassword = lazy(
  async () => await import('@/pages/Security/ChangePassword'),
);

const RecoverPassword = lazy(
  async () => await import('@/pages/Security/RecoverPassword'),
);
const NewPassword = lazy(
  async () => await import('@/pages/Security/NewPassword'),
);
const AccountActivity = lazy(
  async () => await import('@/pages/Security/AccountActivity'),
);

const TwoFactorAuth = lazy(
  async () => await import('@/pages/Security/TwoFactorAuth'),
);

export default [
  {
    path: 'security',
    element: <LandingPage />,
  },
  {
    path: '/security/two-factor-auth',
    element: <TwoFactorAuth />,
  },
  {
    path: 'security/change-password',
    element: <ChangePassword />,
  },
  {
    path: '/security/change-password/recovery',
    element: <RecoverPassword />,
  },
  {
    path: '/security/change-password/new',
    element: <NewPassword />,
  },
  {
    path: '/security/account-activity',
    element: <AccountActivity />,
  },
];
