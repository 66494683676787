import { ExternalLoginProviders } from '@nosinovacao/nosid-mfe-common';
import { BaseApi } from '../base-api';

type GoogleInitProvider = undefined | null;

type AppleInitProvider = AppleSignInAPI.AuthI | null;

type FacebookInitProvider = facebook.FacebookStatic;

type InitProviders =
  | GoogleInitProvider
  | AppleInitProvider
  | FacebookInitProvider;

export abstract class ExternalProvider<T = InitProviders> extends BaseApi {
  private oauthClientInternal: Promise<T | null> | null = null;

  get oAuthClient(): Promise<T | null> {
    if (!this.oauthClientInternal) {
      this.oauthClientInternal = this.initProvider();
    }
    return this.oauthClientInternal;
  }

  abstract initProvider(): Promise<T | null>;

  abstract login(): Promise<boolean>;

  abstract logout(): Promise<void>;

  protected async addSocialNetwork(
    provider: ExternalLoginProviders,
    token: string,
  ) {
    return await this.axios
      .post(
        this.config.APIConfiguration.PortalApi.AddSocialNetwork,
        {},
        {
          headers: {
            'Social-Network-Authorization': `${provider} ${token}`,
          },
        },
      )
      .then(() => true);
  }

  protected async finishLogout(
    provider: ExternalLoginProviders,
  ): Promise<void> {
    await this.axios.delete(
      this.config.APIConfiguration.PortalApi.DelSocialNetwork,
      {
        params: {
          authProvider: provider,
        },
      },
    );
  }
}
