export const LOGIN_FINAL_REDIRECT_URL_STORAGE_KEY =
  'NOSID-Portal-LoginFinalRedirectUrl';
export const REDIRECT_URI_STORAGE_KEY = 'NOSID-Portal-RedirectUri';
export const TOKEN_STORAGE_KEY = 'NOSID-Portal-UserAppToken';
export const INVALID_DEVICE_DATE_ROUTE_STORAGE_KEY =
  'NOSID-Portal-InvalidDeviceDateRoute';
export const CLIENT_ID_STORAGE_KEY = 'NOSID-Portal-ClientId';
export const LOGIN_REDIRECT_URI = '/login-complete';
export const LOGOUT_REDIRECT_URI = '/';
export const SILENT_REDIRECT_URI = '/silent-token-renew';
export const CONTACT_VALIDATION_FLOW_STATE = 'NOSID-CONTACTVALIDATION-STATE';
export const PASSWORD_RECOVERY_STATE = 'NOSID-PASSWORDRECOVERY-STATE';
export const PROFILE_FLOW_STATE = 'NOSID-PROFILE-STATE';
export const ACCOUNT_ASSOCIATION_FLOW_STATE = 'NOSID-ACCOUNTASSOCIATION-STATE';
export const LOGIN_HINT_STORAGE_KEY = 'NOSID-SignIn-LoginHint';
