import { ExternalLoginProviders } from '@nosinovacao/nosid-mfe-common';
import { ExternalProvider } from './external-provider';

type FacebookClient = facebook.FacebookStatic;
type StatusResponse = facebook.StatusResponse;
export class FacebookExternalProvider extends ExternalProvider<FacebookClient> {
  async initProvider(): Promise<FacebookClient | null> {
    return await new Promise((resolve) => {
      //	Async load facebook sdk script
      const node = document.createElement('script');
      node.src = this.config.ExternalLoginProviders.Facebook.API;
      node.async = true;

      // Retrieve the singleton for the FB library on load script
      node.onload = () => {
        FB.init({
          appId: this.config.ExternalLoginProviders.Facebook.AppId,
          cookie: false,
          version: this.config.ExternalLoginProviders.Facebook.Version,
        });

        resolve(FB);
      };

      node.onerror = () => {
        resolve(null);
      };

      //	Inject script into head element
      document.getElementsByTagName('head')[0].appendChild(node);
    });
  }

  async login(): Promise<boolean> {
    return await this.oAuthClient.then(async (client) => {
      if (!client) {
        return false;
      }
      return await new Promise<boolean>((resolve, reject) => {
        client.login(
          (loginResponse: StatusResponse) => {
            if (loginResponse.status === 'connected') {
              resolve(
                this.addSocialNetwork(
                  ExternalLoginProviders.Facebook,
                  loginResponse.authResponse.accessToken ?? '',
                ),
              );
            } else {
              reject(new Error());
            }
          },
          {
            scope: this.config.ExternalLoginProviders.Facebook.Scope,
          },
        );
      });
    });
  }

  async logout(): Promise<void> {
    await this.oAuthClient
      .then(async (client) => {
        client?.logout();
        await this.finishLogout(ExternalLoginProviders.Facebook);
      })
      .catch(async (e) => {
        await this.finishLogout(ExternalLoginProviders.Facebook);
      });
  }
}
